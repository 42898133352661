<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>用户管理</a-breadcrumb-item>
    <a-breadcrumb-item>手动充值</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 日期： -->
    <span class="tool-title">日期：</span>
    <a-range-picker
      v-model:value="dateRange"
      :format="dateFormat"
    />
    <!-- UID： -->
    <span class="tool-title">UID：</span>
    <a-input v-model:value="uid" style="width: 160px;" placeholder="请输入" />
    <!-- 操作 -->
    <a-button type="primary" style="margin-left: 16px;" @click="handleSearch">查询</a-button>
    <a-button style="margin-left: 16px;" @click="handleReset">重置</a-button>
    <div style="flex: 1;"></div>
    <!-- 弹簧 -->
    <div style="flex: 1;"></div>
    <a-button type="primary" @click="touchAdd"><plus-outlined />新增充值</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <!-- 昵称 -->
      <template v-if="column.key === 'charge_type'">
        <template v-for="item in RechargeTypes">
          <span :key="item.value" v-if="item.value === record[column.key]">{{ item.text }}</span>
        </template>
      </template>
      <!-- 数量 -->
      <template v-if="column.key === 'number'">
        {{ record.type === 1 ? '+' : '-' }}{{ record.charge_type === 'coin' ? record['coin'] : record['days'] }}
      </template>
    </template>
  </a-table>
  <!-- 新增、编辑弹窗 -->
  <Add ref="RefAdd" @success="getRechargeList" />
</template>

<script setup>
import { onBeforeMount, ref, reactive, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import { rechargeList } from '@/api/operate'
import Add from './components-recharge/Add.vue'
import { RechargeTypes } from '@/utils/constantList'

// 单个子组件暴露体初始化
let RefAdd = ref(null)
// 加载
let isLoading = ref(false)
// 日期范围
let dateRange = ref([dayjs().subtract(30, 'day'), dayjs()])
// 日期format
const dateFormat = 'YYYY-MM-DD'
// UID
let uid = ref(undefined)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '操作时间',
    dataIndex: 'trade_at',
    key: 'trade_at'
  },
  {
    title: 'UID',
    dataIndex: 'user_id',
    key: 'user_id'
  },
  {
    title: '充值类型',
    dataIndex: 'charge_type',
    key: 'charge_type'
  },
  {
    title: '数量',
    dataIndex: 'number',
    key: 'number'
  },
  {
    title: '操作人',
    dataIndex: 'create_username',
    key: 'create_username'
  },
  {
    title: '充值原因',
    dataIndex: 'reason',
    key: 'reason'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 获取消费列表
  getRechargeList()
})

// 添加充值
function touchAdd () {
  nextTick(() => {
    RefAdd.value.showDrawer() //调用子组件的方法
  })
}

// 查询
function handleSearch () {
  pagination.current = 1
  // 获取列表
  getRechargeList()
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getRechargeList()
}

// 重置
function handleReset () {
  // 筛选项重置
  dateRange.value = null
  uid.value = undefined
  // 分页重置
  pagination.current = 1
  getRechargeList()
}

// 获取消费列表
function getRechargeList () {
  isLoading.value = true
  const params = {
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    user_id: uid.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  rechargeList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

</script>

<style scoped>
.tool-view {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}
.operation-item {
  margin-right: 8px;
}
</style>