<template>
  <a-drawer
    title="新增充值"
    width="700"
    :visible="visible"
    :closable="false"
    @close="onClose"
  >
    <!-- from -->
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- UID -->
      <a-form-item
        label="UID"
        name="user_id"
        :rules="[{ required: true, validator: validatorUID }]"
      >
        <a-input
          class="form-input" 
          v-model:value="formState.user_id"
          placeholder="请输入"
        >
        </a-input>
      </a-form-item>
      <!-- 充值方式 -->
      <a-form-item
        label="充值方式"
        name="type"
        :rules="[{ required: true }]"
      >
        <a-radio-group
          class="form-input"
          v-model:value="formState.type"
        >
          <a-radio
            v-for="item in RechargeMethods"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <!-- 充值类型 -->
      <a-form-item
        label="充值类型"
        name="charge_type"
        :rules="[{ required: true }]"
      >
        <a-radio-group
          class="form-input"
          v-model:value="formState.charge_type"
        >
          <a-radio
            v-for="item in RechargeTypes"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <!-- 数量 -->
      <a-form-item
        v-if="formState.charge_type === 'coin'"
        label="数量"
        name="coin"
        :rules="[{ required: true, validator: validatorNumber }]"
      >
        <a-input
          class="form-input" 
          v-model:value="formState.coin"
          placeholder="请输入"
          suffix="个"
        >
        </a-input>
      </a-form-item>
      <!-- 数量 -->
      <a-form-item
        v-else
        label="数量"
        name="days"
        :rules="[{ required: true, validator: validatorNumber }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.days"
          placeholder="请输入"
          suffix="天"
        >
        </a-input>
      </a-form-item>
      <!-- 充值原因 -->
      <a-form-item
        label="充值原因"
        name="reason"
        :rules="[{ required: true }]"
      >
        <a-textarea
          class="form-input" 
          v-model:value="formState.reason"
          placeholder="请输入"
          :auto-size="{ minRows: 3, maxRows: 3 }"
        >
        </a-textarea>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <div class="footer-view">
        <div style="flex: 1;"></div>
        <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
        <a-button type="primary" @click="touchSubmit">确定</a-button>
      </div>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { Modal } from 'ant-design-vue'
import { reactive, ref } from 'vue'
import { RechargeTypes, RechargeMethods } from '@/utils/constantList'
import Loading from '@/components/Loading'
import Pub from '@/utils/public'
import { rechargeSave } from '@/api/operate'
import { message } from 'ant-design-vue'

// emit
const emit = defineEmits(['success'])
// from
const RefForm = ref(null)
// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 表单参数
const formState = reactive({
  // UID
  user_id: undefined,
  // 充值方式
  type: 1,
  // 充值类型
  charge_type: 'coin',
  // 充值数量
  coin: undefined,
  // 充值天数
  days: undefined,
  // 充值原因
  reason: undefined
})

// 显示
function showDrawer () {
  // 显示
  visible.value = true
}

// 获取分发详情
// function getDistributeDetail () {
//   distributeDetail({
//     id: recordID.value
//   }).then(res => {
//     isLoading.value = false
//     const { code, msg, data } = res
//     if (code === 0) {
//       // 设置表单显示
//       nextTick(() => {
//         // 分发项目
//         formState.project = data.project_id
//         // 合作方式
//         formState.settlement = data.cop_type
//         // 价格
//         formState.price = Pub.KEEP_NUMBER_DECIMAL(data.money / 100, 2)
//         // 分成比例
//         formState.proportion = data.cop_type === 1 ? undefined : data.cop_rate
//         // 授权开始
//         formState.copyrightTermStart = dayjs(data.copyright_start_at)
//         // 授权结束
//         formState.copyrightTermEnd = dayjs(data.copyright_end_at)
//         // 是否独家
//         formState.exclusive = data.is_unique_auth
//       })
//     } else {
//       message.error(res.message || msg)
//     }
//   })
// }

// 关闭
function onClose () {
  if (!isLoading.value) {
    visible.value = false
    resetFields()
  }
}

// 重置
function resetFields () {
  RefForm.value.resetFields()
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    rechargeSave(formState).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success(`${formState.type === 1 ? '增加' : '扣除'}成功`)
        emit('success')
        onClose()
      } else {
        Modal.error({
          title: '提示',
          content: res.message || msg,
          okText: '知道了'
        })
      }
    })
  }).catch(() => {
    message.error('新增失败')
  })
}

// 效验 - UID
function validatorUID (rule, value) {
  if (!value) {
    return Promise.reject('请输入${label}')
  } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
    return Promise.reject('必须为数字')
  }
  return Promise.resolve()
}

// 效验 - 数量
function validatorNumber (rule, value) {
  if (!value) {
    return Promise.reject('请输入${label}')
  } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
    return Promise.reject('必须为正整数')
  }
  return Promise.resolve()
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
.form-input {
  width: 80%;
}
.form-item-clear {
  margin-bottom: 0;
}
.footer-view {
  display: flex;
  align-items: center;
}
.footer-hint {
  color: #5A5A5A;
}
</style>